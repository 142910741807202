import Head from 'next/head';
import PropTypes from 'prop-types';

import { useMessageContext } from '@context/MessageContext';
import { Loader } from '@components';

import sty from './auth.module.scss';

export default function AuthWrapper({
  pageTitle,
  msg,
  children,
  showLoading,
  renderOverlay = '',
  theme = {},
}) {
  const { success } = useMessageContext();
  const {
    brandLogo = '/images/brand-logo.png',
    bgColor = '#e0e5e7',
    headerColor = '#475260',
    welcomeImg = '/images/pl-welcome.png',
    welcomeImgWebp = '/images/pl-welcome.webp',
  } = theme;

  return (
    <>
      <Head>
        <title>{pageTitle} | Payment Loyalty</title>
      </Head>
      <div className={sty['auth-wrapper']}>
        {/* form content */}
        <div className={sty['auth-content-wrapper']} style={{ backgroundColor: bgColor }}>
          {showLoading && <Loader />}

          <img className={sty['pl-logo']} src={brandLogo} alt="logo" />

          <div className={sty.content}>
            <div className={sty.innerContainer}>
              {/* Header */}
              <div className={sty.heading} style={{ color: headerColor }}>
                {pageTitle === 'Login' && <div>{msg}</div>}
                <header>Welcome to the Campaign Portal</header>
                {pageTitle !== 'Login' && <div>{msg}</div>}
              </div>

              {/* shows global error/success Message for Auth (/, /register, /forgot-password, /reset-password) */}
              {success && (
                <div className="alert alert-success " role="alert">
                  {success}
                </div>
              )}

              {/* form for auth flow */}
              {children}
            </div>
          </div>
        </div>

        {/* render overlay */}
        {renderOverlay}

        {/* device image */}
        <div className={sty['img-section']} style={{ backgroundColor: bgColor }}>
          {/* <img src={HOME_WIN} className={sty['img-section__img']} alt="pax-img" />
          <img src={HOME_NOM} className={sty['img-section__img']} alt="pax-img" /> */}

          <picture>
            <source srcSet={welcomeImgWebp} type="image/webp" />
            <source srcSet={welcomeImg} type="image/png" />
            <img src={welcomeImg} className={sty['img-section__img']} alt="pax-img" />
          </picture>
        </div>
      </div>
    </>
  );
}

AuthWrapper.propTypes = {
  pageTitle: PropTypes.string,
  msg: PropTypes.string,
  children: PropTypes.any,
  showLoading: PropTypes.bool,
  renderOverlay: PropTypes.any,
  theme: PropTypes.any,
};
