import Head from 'next/head';
import { useRouter } from 'next/router';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Button } from '@components';
import URL from '@helpers/url-constant';

import sty from './userform.module.scss';

import PLUS_ICON from '../../../public/images/plus.svg';

function UserForm({ title, children, newUser, status, message }) {
  const router = useRouter();

  function goToSettings() {
    router.push(URL.SETTINGS);
  }

  function handleNewUser() {
    if (!newUser) {
      router.push(URL.USER_CREATE);
    }
  }

  return (
    <>
      <Head>
        <title>{`${title} | Payment Loyalty`}</title>
      </Head>
      <div className={sty['users-wrap']}>
        <h2 className="page-title">{title}</h2>
        {!status && (
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        )}
        <div className="d-flex mb-2">
          <div className={cx('d-flex', 'bg-gray', sty['info-row'])}>
            <div className={cx(sty['u-col-1'], 'form-label', 'mr-2')}>
              <span>Name</span>
            </div>
            <div className={cx(sty['u-col-2'], 'form-label', 'mr-2')}>
              <span>Surname</span>
            </div>
            <div className={cx(sty['u-col-3'], sty.email, 'form-label', 'mr-2')}>
              <span>Email</span>
            </div>
          </div>
          <div className={cx(sty['u-col-5'], 'mr-2')}>
            <button type="button" className="action-btn" onClick={handleNewUser}>
              <img src={PLUS_ICON} alt="plus-icon" />
              <span>New User</span>
            </button>
          </div>
          <div className={cx(sty['u-col-4'], 'form-label', 'text-center')}>
            <span>Status</span>
          </div>
        </div>

        {children}

        <div className="mt-50 text-center">
          <Button type={1} colorType={2} label="Back" handleClickNext={goToSettings} />
        </div>
      </div>
    </>
  );
}

UserForm.propTypes = {
  title: PropTypes.string,
  status: PropTypes.bool,
  message: PropTypes.string,
  children: PropTypes.any,
  newUser: PropTypes.bool,
};

export default UserForm;
