export const getProviderOption = paymentProviderType => {
  return paymentProviderType.reduce(
    (acc, { image, id, label }) =>
      acc.concat({ value: id, label: providerLabel({ image, label }) }),
    [],
  );
};

export const providerLabel = ({ image, label }) => <img src={`/images/psp/${image}`} alt={label} />;

export const currencyMap = {
  usd: '$',
  gbp: '£',
  cad: '$',
  eur: '€',
};
